<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/FooterHypd.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
