<template>
  <div class="header">
    <a href="/" class="logo">HYPD.</a>
  </div>
</template>

<style scoped>
.header {
  padding: 16px 0;
  border-bottom: 1px solid #e9eaee;
  background: transparent;
}
a {
  text-decoration: none;
}
.logo {
  font-family: Ginger-Regular;
  font-size: 32px;
  margin: 16px auto;
  color: #000;
}
</style>
